
import { defineComponent, PropType } from "vue";
import { FareFamily } from "@/models/offer_models";
import $ from "jquery";
import "slick-carousel";
import { TicketBooking } from "@/models/order_modes";
import flightBookingController from "@/composables/flight_booking";
import { OfferService } from "@/services/offer_service";
import { ResponseStatusType } from "@/constants/response_status_type";

export default defineComponent({
  name: "booking_tariff",
  setup() {
    const { state } = flightBookingController();
    return { ...state };
  },
  created() {
    this.loading = true;
    this.loadingMessage = this.$t("CheckAviabilty.TariffAvailabilityCheck");
    OfferService.getFareFamily(this.ticketBooking.buyId).then((result) => {
      this.loading = false;
      this.loadingMessage = "";
      if (result.status == ResponseStatusType.Ok) {
        this.tariffs = result;
      }
    });
  },
  updated() {
    this.init();
  },
  methods: {
    onTariffClick: function(tariff: FareFamily) {
      this.selectedTariff = tariff;
    },
    init: function() {
      let slickItem = $("#tariffs-slider");
      if (slickItem.hasClass("slick-initialized")) {
        slickItem.slick("unslick");
      }

      $("#tariffs-slider").slick({
        rows: 0,
        speed: 1000,
        dots: false,
        arrows: true,
        lazyLoad: "progressive",
        autoplay: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        appendArrows: "#tariffs-controls",
        prevArrow:
          '<button class="tariffs-button" aria-label="previous"><svg width="12" height="12" viewbox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.668 1.666L1.335 11l9.333 9.333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
        nextArrow:
          '<button class="tariffs-button"  aria-label="next"><svg width="12" height="12" viewbox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.332 1.666L10.665 11l-9.333 9.333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg></button>',
        cssEase: "cubic-bezier(0.27, 1, 0.27, 1)",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    },
  },
});

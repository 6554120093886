
import {defineComponent, PropType} from "vue";
import {FlightOrderStatus} from '@/models/flight_order_status';
import SearchResultItem from "@/components/flight/search_result_item.vue";
import SearchResultMoreItem from "@/components/flight/search_result_more_item.vue";
import {Segment, Ticket} from "@/models/flight_models";
import flightBookingController from "@/composables/flight_booking";

export default defineComponent({
  name: "booking_flight_order_detail",
  components: {
    SearchResultItem,
    SearchResultMoreItem,
  },
  props: {
    'ticket': {type: Object as PropType<Ticket>}
  },
  data() {
    return {
      showMoreDetails: false,
      paidStatus: FlightOrderStatus.PAID
    }
  },
  setup(){
    const {state, generatePassengers} = flightBookingController();
    return{
      ...state
    }
  },
  methods: {
    priceFormat(price: number): string {
      return price.toLocaleString('uz-UZ', {
        style: 'currency',
        currency: 'UZS',
        minimumFractionDigits: 0
      })
    }
  },
});
